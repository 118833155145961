<template>
  <div class="mainPageConfigBox">
    <ds-header title="主页面配置"></ds-header>
    <a-form layout="inline">
      <a-form-item label="主页面主题：">
        <a-input v-model.trim="searchForm.homePageName" placeholder="请输入主页面主题" class="inputClass" />
      </a-form-item>
      <a-form-item label="所属项目">
        <a-tree-select
          v-model="searchForm.projectId"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
      <a-form-item label="透出端">
        <a-select
          placeholder="请选择透出端"
          v-model="searchForm.homePagePenetrate"
          :style="{width: '200px'}"
        >
          <a-select-option :value="1">
            APP
          </a-select-option>
          <a-select-option :value="2">
            小程序
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 添加 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="enableOrDisable(record)"> {{ `${ record.status === 1 ? '禁用' : '启用' }` }} </a-button>
<!--          <a-button class="btnClass" type="link" @click="deleteById(record)"> 删除 </a-button>-->
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import {getMainPageConfigList, deleteMainPageConfigById, enableOrDisable} from "@/api/mainPageConfig/mainPageConfig";
import {getProjects} from "@/api/common";
import {mapState} from "vuex";

export default {
  name: 'MainPageConfig',
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    })
  },
  data() {
    return {
      searchForm: {
        homePageName: '',
        projectId: undefined,
        // 透出端
        homePagePenetrate: undefined
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "主页面主题",
            dataIndex: "homePageName",
            key: "homePageName",
          },
          {
            title: '透出端',
            dataIndex: 'homePagePenetrate',
            customRender(text) {
              return text === 1 ? 'APP' : '小程序'
            }
          },
          {
            title: "所属项目",
            dataIndex: "projectNameStr",
            key: "projectNameStr",
          },
          {
            title: "页面数量",
            dataIndex: "homePageSize",
            key: "homePageSize",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            customRender(text) {
              return text ? '启用' : '禁用'
            }
          },
          {
            title: "创建时间",
            dataIndex: "createTimeStr",
            key: "createTimeStr",
          },
          {
            title: "创建人",
            dataIndex: "createUserName",
            key: "createUserName",
          },
          {
            title: "操作",
            dataIndex: "operation",
            width: "220px",
            scopedSlots: { customRender: "action" },
            fixed:'right'
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    }
  },
  async created() {
    // 查询list
    await this.getList();
  },
  methods: {
    /**
     * 查询列表
     */
    async getList() {
      const params = this.getParams()
      const res = await getMainPageConfigList(params);
      const { code, data: { data, total }, msg } = res
      if (code === '200') {
        this.table.dataInfo = data
        this.table.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 整合参数
    getParams() {
      return {
        ...this.searchForm,
        pageNo: this.table.pagination.current,
        pageSize: this.table.pagination.pageSize
      }
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    /**
     * 搜索
     */
    search() {
        this.getList()
    },
    /**
     * 重置search
     */
    resetSearchForm() {
      this.searchForm = {
        homePageName: '',
        projectId: undefined,
        homePagePenetrate: undefined
      }
      this.getList()
    },
    /**
     * 新建主页面
     */
    add() {
      this.$router.push({
        path: '/pageManagement/mainPageConfig/add',
        query: {
          type: 'add'
        }
      })
    },
    /**
     * 编辑
     */
    edit(row) {
      this.$router.push({
        path: '/pageManagement/mainPageConfig/add',
        query: {
          type: 'edit',
          id: row.homePageId
        }
      })
    },
    /**
     * 启用/禁用
     */
    enableOrDisable(row) {
      const params = {
        homePageId: row.homePageId,
        status: row.status === 1 ? 0 : 1
      }
      const that = this
      this.$confirm({
        title: `确定要${row.status === 1 ? '禁用': '启用'}当前数据吗？`,
        async onOk() {
          const res = await enableOrDisable(params)
          const { code, msg } = res
          if(code === '200') {
            that.$message.success('操作成功！')
            that.table.pagination.current = 1
            await that.getList();
          } else {
            that.$message.error(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    },
    /**
     * 删除
     */
    deleteById(row) {
      const that = this
      this.$confirm({
        title: '确定要删除当前数据吗?',
        async onOk() {
          const res = await deleteMainPageConfigById(row.homePageId)
          const { code, msg } = res
          if(code === '200') {
            that.$message.success('删除成功！')
            that.table.pagination.current = 1
            await that.getList();
          } else {
            that.$message.error(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    /**
     * 查看
     */
    view(row) {
      this.$router.push({
        path: '/pageManagement/mainPageConfig/add',
        query: {
          type: 'view',
          id: row.homePageId
        }
      })
    }
  },
  mounted() {
    let handleResize = ()=> {
      let width = document.getElementsByClassName('mainPageConfigBox')[0].clientWidth;
      console.log(width,'=====');
      if(width>1600){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
}
</script>

<style scoped lang="scss">

</style>
